//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BackButton from '@/components/BackButton'
import TermsAndConditionsText from '@/components/Legal/TermsAndConditions'
export default {
    name: 'TermsAndConditions',
    components: {
      BackButton,
      TermsAndConditionsText
    },
    metaInfo(){
        return {
            title:'Terms and Condition'
        }
    },
}
